import "./App.css";
import HomePage from "./components/home/HomePage.jsx";
import ReactPDF, { PDFDownloadLink, Font } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Cheatsheet from "./cheatsheet/Cheatsheet";

function App() {
  var [cs, setCs] = useState(null);

  useEffect(() => {
    const cheatsheet = new Cheatsheet();
    setCs(cheatsheet.toPdf());
    console.log("ahoy", cs);

    Font.register({
      family: "Roboto",
      src: "http://fonts.gstatic.com/s/notosans/v6/0Ue9FiUJwVhi4NGfHJS5uA.ttf",
    });

    Font.registerEmojiSource({
      format: "png",
      url: "https://twemoji.maxcdn.com/2/72x72/",
    });
  }, []);

  return (
    <div className="App">
      {cs && (
        <PDFDownloadLink document={cs} fileName="somename.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink>
      )}
      <HomePage />
    </div>
  );
}

export default App;
