import rawDataToPdf from "./CheatsheetPdfHelper";

const testData = [
  [
    {
      title: "hello",
      data: [
        {
          key: "h\u2090 यह एक को इनका 😀 worldfdjsklafj ksajdfkl jsakldfj dklsajf sakdjfladks jflksdjf klasjdkfl j",
          val: "value",
        },
        { key: "hi hi", val: "hi hi val" },
      ],
    },
    {
      title: "hello",
      data: [
        { key: "hello world", val: "helwor val" },
        { key: "hi hi", val: "these nuts" },
      ],
    },
  ],
  [{ title: "nuts", data: [{ key: "nuts world", val: "nuts nuts" }] }],
];

const newData = [
  {
    key: "c872d146-00a3-4d3e-b957-3acf73bc8813",
    val: [
      {
        key: "09f020cb-fb67-4d6f-ba6d-4611ff3214e9",
        val: [
          {
            key: "888af343-1705-4427-923d-5043589bdd8e",
            left: "Sora",
            right: "Kaira",
          },
          {
            key: "b1e33664-b4d1-4cbc-a621-5abf90c180e7",
            left: "Donald",
            right: "Goofy",
          },
        ],
        title: "Kingdom",
      },
    ],
  },
  {
    key: "1e68331d-b860-4497-b1de-08abdffda4ae",
    val: [
      {
        key: "1d1ccdb8-90bd-43b6-a605-dd9ef3ec6faf",
        val: [
          {
            key: "7da09ec0-dea5-46e3-9880-ec186db6c559",
            left: "Mickey",
            right: "Minnie",
          },
        ],
        title: "Hearts",
      },
    ],
  },
];

class Cheatsheet {
  constructor() {
    this.rawData = [[], [], []];
    this.columns = 1;
  }

  toPdf(data) {
    return rawDataToPdf(data);
  }

  toJsx() {}
}

export default Cheatsheet;
