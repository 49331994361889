import React, { useState, useEffect, useRef } from "react";
import SheetCardBody from "./SheetCardBody";
import { v4 as uuidv4 } from "uuid";

// title={row.title}
//           cards={row.val}
//           setTitle={setTitle}
//           updateRows={updateRows}

function SheetCard(props) {
  const [title, setTitle] = useState("");
  const [sheetCardBody, setSheetCardBody] = useState([]);
  const inputRef = useRef();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // props.col.push({
      //   key: uuidv4(),
      //   val: [],
      //   title: "",
      // });

      props.cards.push({
        key: uuidv4(),
        left: "",
        right: "",
      });

      props.updateRows(props.cards);

      console.log("Thom", props.cards);

      setSheetCardBody((s) => [
        ...s,
        <tr key={s.length}>
          <td>
            <SheetCardBody
              onEnterPressed={handleKeyPress}
              shouldFocus={true}
            ></SheetCardBody>
          </td>
        </tr>,
      ]);
    }
  };

  useEffect(() => {
    console.log("props:", props);

    props.cards.push({
      key: uuidv4(),
      left: "",
      right: "",
    });

    props.updateRows(props.cards);

    setSheetCardBody([
      <tr key={0}>
        <td>
          <SheetCardBody
            onEnterPressed={handleKeyPress}
            shouldFocus={false}
          ></SheetCardBody>
        </td>
      </tr>,
    ]);

    inputRef.current.focus();
  }, []);

  const updateCardBodyEntry = (index, left, right) => {
    console.log("Papaya!");
    props.cards[index].left = left;
    props.cards[index].right = right;
    props.updateRows(props.cards);
  };

  return (
    <table className="w-full max-w-xl rounded-lg overflow-hidden shadow-md mb-6">
      <thead className="w-full bg-green-200">
        <tr>
          <th className="py-2 px-8 text-left">
            <input
              className="max-w-full bg-transparent"
              value={props.title}
              placeholder="Card title"
              onChange={(e) => props.setTitle(e.target.value)}
              maxLength="50"
              ref={inputRef}
            ></input>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.cards.map((card, i) => (
          <tr key={card.key}>
            <td>
              <SheetCardBody
                onEnterPressed={handleKeyPress}
                shouldFocus={i != 0}
                updateBody={updateCardBodyEntry.bind(null, i)}
                left={props.cards[i].left}
                right={props.cards[i].right}
              ></SheetCardBody>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SheetCard;
