import { useState, useEffect } from "react";
import SheetCard from "./SheetCard";
import { v4 as uuidv4 } from "uuid";

function SheetCardColumn(props) {
  const [sheetCards, setSheetCards] = useState([]);
  console.log("hihi");
  const addNewCard = () => {
    // setSheetCards((s) => [...s, <SheetCard key={s.length}></SheetCard>]);

    props.col.push({
      key: uuidv4(),
      val: [],
      title: "",
    });
    props.updateCol(props.col);
    console.log("nutty", props.col);
  };

  const setTitle = (index, title) => {
    props.col[index].title = title;
    props.updateCol(props.col);
  };

  const updateRows = (index, row) => {
    props.col[index].val = row;
    props.updateCol(props.col);
  };

  useEffect(() => {
    console.log("hit");
    addNewCard();
  }, []);

  // {
  //                 key: "card_key",
  //                 title: "Title",
  //                 val: [
  //                     {   key: "fdsaf"
  //                         left: "cheatsheet key",
  //                         right: "fdsa"
  //                     }
  //                 ]
  //             }

  useEffect(() => {
    console.log("rerendering");
  });

  return (
    <div className="w-full flex flex-col items-center">
      {/* {sheetCards} */}
      {props.col.map((row, i) => (
        <SheetCard
          key={row.key}
          title={row.title}
          cards={row.val}
          setTitle={setTitle.bind(null, i)}
          updateRows={updateRows.bind(null, i)}
        ></SheetCard>
      ))}
      <div
        className="inline-block rounded-full m-4 bg-green-200 px-4 py-2 cursor-pointer"
        onClick={addNewCard}
      >
        Add row
      </div>
    </div>
  );
}

export default SheetCardColumn;
