import SheetCardColumn from "./SheetCardColumn";
import { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { pdf } from "@react-pdf/renderer";
import Cheatsheet from "../../cheatsheet/Cheatsheet";

// TODO: using index as keys is bad lol

function NewSheetPage() {
  const [rawData, setRawData] = useState([]);

  // const addNewCardCol = useCallback(() => {
  //   if (rawData.length < 3) {
  //     // setSheetCardCols((s) => [
  //     //   ...s,
  //     //   <SheetCardColumn key={s.length}></SheetCardColumn>,
  //     // ]);

  //     setRawData((rawData) => {
  //       rawData.push({
  //         key: uuidv4(),
  //         val: [],
  //       });
  //       return rawData;
  //     });
  //   }
  // }, [rawData.length]);

  const addNewCardCol = () => {
    console.log("testt");
    if (rawData.length < 3) {
      // setSheetCardCols((s) => [
      //   ...s,
      //   <SheetCardColumn key={s.length}></SheetCardColumn>,
      // ]);
      console.log("testtest");
      setRawData((rawData) => {
        const rawDataCopy = [...rawData];
        rawDataCopy.push({
          key: uuidv4(),
          val: [],
        });
        return rawDataCopy;
      });
      console.log(rawData);
    }
  };

  const updateRawDataColumn = (index, column) => {
    setRawData((rawData) => {
      const rawDataCopy = [...rawData];
      rawDataCopy[index].val = column;
      return rawDataCopy;
    });

    console.log("thomas", rawData);
  };

  useEffect(() => {
    addNewCardCol();
    console.log("karate", rawData);
    // eslint-disable-next-line
  }, []);

  function download(dataurl, filename) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
  }

  const getRawData = () => {
    console.log(`RAW DATA: ${JSON.stringify(rawData, undefined, 2)}`);
    const cs = new Cheatsheet();
    const blob = pdf(cs.toPdf(rawData))
      .toBlob()
      .then(function (blob) {
        var blobUrl = URL.createObjectURL(blob);
        download(blobUrl, "helloWorld.pdf");
      });
    console.log("hihi", blob);
  };

  return (
    <>
      <div
        className="inline-block bg-green-200 px-4 py-2 mb-6 rounded-lg  shadow-md cursor-pointer"
        onClick={addNewCardCol}
      >
        Add column {rawData.length}/3
      </div>

      <div
        className="inline-block bg-green-200 px-4 py-2 mb-6 rounded-lg  shadow-md cursor-pointer"
        onClick={getRawData}
      >
        Get the shit
      </div>

      <div className="flex flex-row mx-12 space-x-2">
        {rawData.map((col, i) => {
          console.log("in the heights", col.key);
          return (
            <SheetCardColumn
              key={col.key}
              col={[...col.val]}
              updateCol={updateRawDataColumn.bind(null, i)}
            ></SheetCardColumn>
          );
        })}
      </div>
    </>
  );
}

export default NewSheetPage;
