import NewSheetPage from "../cheatsheet/NewSheetPage";

function HomePage() {
  return (
    <div>
      <div className="my-12">CS Maker {"\u2070"}</div>
      <NewSheetPage></NewSheetPage>
    </div>
  );
}

export default HomePage;
