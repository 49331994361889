import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

function rawDataToPdf(testData) {
  // Font.register({
  //   family: "Roboto",
  //   src: "http://fonts.gstatic.com/s/notosans/v6/0Ue9FiUJwVhi4NGfHJS5uA.ttf",
  // });

  // Font.registerEmojiSource({
  //   format: "png",
  //   url: "https://twemoji.maxcdn.com/2/72x72/",
  // });

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
      paddingHorizontal: "1cm",
      paddingVertical: "1cm",
      fontFamily: "Roboto",
      // fontFamily: "sans-serif",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      maxWidth: "15vw",
    },

    columns: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      // border: "1pt solid #000",
    },

    rows: {
      display: "flex",
      flexDirection: "column",
      // borderBottom: "1pt solid #000",
      flexGrow: 1,
      maxWidth: "50vw",
      padding: "6pt",
    },

    cardHeader: {
      backgroundColor: "#A7F3D0",
      padding: "5pt",
      borderBottom: "1pt solid #000",
      borderTopRightRadius: "10pt",
      borderTopLeftRadius: "10pt",
      // overflow: "hidden",
    },

    cardBody: {
      // border: "1pt solid green",
      // borderLeft: "1pt solid #000",
      // borderRight: "1pt solid #000",
      padding: "5pt",
      flexGrow: 1,
      maxWidth: "50%",
    },

    dummy: {
      // borderBottom: "1pt solid #000",
      // borderBottomRightRadius: "10pt",
      // borderBottomLeftRadius: "10pt",
    },

    cardStyle: {
      // padding: "5pt",
      // border: "1pt solid #000",
      // width: "50%",
      marginBottom: "6pt",
      borderBottom: "1pt solid #000",
      borderRight: "1pt solid #000",
      borderLeft: "1pt solid #000",
      borderTop: "1pt solid #000",
      borderBottomRightRadius: "10pt",
      borderBottomLeftRadius: "10pt",
      borderTopRightRadius: "10pt",
      borderTopLeftRadius: "10pt",
      overflow: "hidden",
    },

    cardKeyValContainer: {
      display: "flex",
      flexDirection: "row",
    },
  });

  // const getRows = (col) =>
  //   col.map((row) => {
  //     return (
  //       <View style={styles.cardStyle}>
  //         <Text style={styles.cardHeader}>{row.title}</Text>
  //         {row.data.map((x) => {
  //           return (
  //             <View style={styles.cardKeyValContainer}>
  //               <Text style={styles.cardBody}>{x.key}</Text>
  //               <Text style={styles.cardBody}>{x.val}</Text>
  //             </View>
  //           );
  //         })}
  //         <View style={styles.dummy}></View>
  //       </View>
  //     );
  //   });

  const getRows = (col) =>
    col.val.map((row) => {
      return (
        <View style={styles.cardStyle}>
          <Text style={styles.cardHeader}>{row.title}</Text>
          {row.val.map((x) => {
            return (
              <View style={styles.cardKeyValContainer}>
                <Text style={styles.cardBody}>{x.left}</Text>
                <Text style={styles.cardBody}>{x.right}</Text>
              </View>
            );
          })}
          <View style={styles.dummy}></View>
        </View>
      );
    });

  // Create Document Component
  const MyDocument = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.columns}>
          {testData != null &&
            testData.length > 0 &&
            testData.map((col) => (
              <View style={styles.rows}>{getRows(col)}</View>
            ))}
        </View>
      </Page>
    </Document>
  );

  // <View style={styles.columns}>
  //   {testData.map((col) => {
  //     return getRows(col)
  //     return col.map((row) => {
  //       console.log(row);
  //       return (
  //         <View style={styles.rows}>
  //           <Text style={styles.cardHeader}>{row.title}</Text>
  //           {row.data.map((x) => {
  //             return <Text style={styles.cardBody}>{x}</Text>;
  //           })}
  //           <View style={styles.dummy}></View>
  //         </View>
  //       );
  //     });
  //   })}

  return MyDocument;
}

export default rawDataToPdf;
