import React, { useState, useRef, useEffect } from "react";

function SheetCardBody(props) {
  const inputRef = useRef();

  useEffect(() => {
    if (props.shouldFocus) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="w-full px-8 py-2 flex justify-between">
      <input
        className="max-w-full "
        value={props.left}
        placeholder="Enter here"
        onChange={(e) => props.updateBody(e.target.value, props.right)}
        maxLength="10"
        onKeyPress={props.onEnterPressed}
        ref={inputRef}
      ></input>{" "}
      <input
        className="max-w-full"
        value={props.right}
        placeholder="Enter here"
        onChange={(e) => props.updateBody(props.left, e.target.value)}
        maxLength="10"
        onKeyPress={props.onEnterPressed}
      ></input>{" "}
    </div>
  );
}

export default SheetCardBody;
